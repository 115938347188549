const filters = [
    'Design',
    'Development',
    'Mobile',
    'Machine Learning',
    'Hardware & IoT',
    '3D'
];

const labProjects = {
    busTracking: {
        imageSelector: `BusTracking`,
        themeColor: `#4F7EF7`,
        title: `Georgia Tech's Official Bus Tracking App`,
        description: `I redesigned Georgia Tech's official bus tracking app, PassioGo, with UI/UX improvements on features such as route searching, real-time map, and alerts.`,
        categories: ['Mobile', 'Design'],
        filter: ['Mobile','Design'],
        link: `/random/bus-tracking`,
        date: `November 2021`,
        stack: [`Figma`],
        info: ``,
    },
    cryptobots: {
        imageSelector: `Cryptobots`,
        themeColor: `#4F7EF7`,
        title: `Cryptobots`,
        description: `Designed a character creation/generator system as a freelance consultant for Cryptobots.`,
        categories: ['Design'],
        filter: ['Design'],
        link: `/random/cryptobots`,
        date: `April 2021`,
        stack: [`Figma`],
        info: ``,
    },
};

export { filters, labProjects };