import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import ProjectHeader from "../../components/project-header/project-header"
import { RoughNotation } from "react-rough-notation"
import { labProjects } from "../../components/data/lab"

import OpenMov from 'file-loader!../../images/projects/bus-tracking/Open.mov'
import NavigateMov from 'file-loader!../../images/projects/bus-tracking/Navigate.mov'
import InfoMov from 'file-loader!../../images/projects/bus-tracking/Info.mov'
import RouteMov from 'file-loader!../../images/projects/bus-tracking/Map.mov'

const theme = labProjects['busTracking'].themeColor;

const ProjectPage = () => (
  <>
    <Seo title="Bus Tracking App" />
    <ProjectHeader projectObj={labProjects['busTracking']} />
    <section className={`fix-top`}>
      <div className={`content--md-center`}>
        <p className={`primary`}>GT's official bus tracking app's current version has design issues when it comes to searching routes, looking up details, following multiple routes in the live map, and more. For a class project, and as a design experiment, I tried redesigning the app. Here's the final version!</p>
      </div>
    </section>
    <section className={`bus-tracking`}>
      <div className={`content--md-center`}>
      <h6 style={{ color: theme}}>Design</h6>
      <h2>Screens</h2>
      <div className={`project-grid-wrapper`}>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={OpenMov} />
              </video>
            </div>
            <h4 className={`center`}>Splash & Start up</h4>
            <p className={`center lh`}>From preliminary questioning and observations, students' main motive to use this app is to look up routes from one point to another. The start screen takes users to the search functionality by default, with tabs to a live map and route information.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={NavigateMov} />
              </video>
            </div>
            <h4 className={`center`}>Search & Navigation</h4>
            <p className={`center lh`}>The current app requires users to check different bus routes, their timings, walking distance to bus stops, and make decisions between different options for travel. This process can be simplified by understanding where the user wants to travel.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={RouteMov} />
              </video>
            </div>
            <h4 className={`center`}>Routes & Map</h4>
            <p className={`center lh`}>Students can look up live locations of buses through easy toggles on a map. Beyond information about the location of bus stops, the app can share more details about the stop such as pictures, significance, and history, allowing students to better discover their campus.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={InfoMov} />
              </video>
            </div>
            <h4 className={`center`}>Information & Updates</h4>
            <p className={`center lh`}>To plan ahead, students should be able to look up bus timings and route information. The info page wraps such information in a simple UI and also incorporates updates and alerts.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={``}>
      <div className={`content--md-center`}>
      <div className={`project-split-wrapper`}>
        <div className={`split`}>
          <h6 style={{ color: theme}}>Interactions</h6>
          <h2>Prototype <span className={`icon-lg`}><FontAwesomeIcon icon={faArrowRight} /></span></h2>
          <p className={`primary margin-bottom`}>Here's the redesign prototyped in Figma. Click or Tap on the app icon to start exploring the app's prototype for yourself!</p>
        </div>
        <div className={`split center-content hide-cursor`}>
          <div>
          <iframe className={`figma-embed`} width={`100%`} height={600} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FWFjkLUFtvySfUY8wU35Qns%2FHCI-Foundations%253A-Homework-2%3Fpage-id%3D0%253A1%26node-id%3D20%253A257%26viewport%3D241%252C48%252C0.07%26scaling%3Dscale-down%26starting-point-node-id%3D20%253A257%26hide-ui%3D1" allowfullscreen></iframe>
          <div className={`project-image remove-margin`}><p className={`caption remove-margin`}>Interactive Prototype</p></div>
          </div>
        </div>
      </div>

        <div className={`center-content add-margin-top`}>
          <Link to='/lab'><button className={`data-hover`}>Back to Lab</button></Link>
        </div>
      </div>
    </section>
  </>
)

export default ProjectPage